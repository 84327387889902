import { Buffer } from "buffer";
import { encode } from "@stablelib/utf8";
import dataFromJson from "../history.json";

export interface HistoricalItem {
  hash?: string;
  year: string;
  level: number;
  text: string;
  title?: string;
}

export interface History {
  events: Array<HistoricalItem>;
}

const processData = (data: History) => {
  data.events = data.events.map((item, index) => {
    const textu8: Uint8Array = encode(item.text);
    const hash = Buffer.from(textu8).toString('hex').slice(0, 6);

    return(
      {...item, hash: hash}
    )
  });
  data.events.reverse();
  return data;
}

const internalData: History = dataFromJson;
export const data = processData(internalData);

export function getJsonData<T = HistoricalItem>(url: string): Promise<T> {
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
