import { InFocusProvider } from "./InFocusContext/InFocusContext";
import { FooterItem } from "./FooterItem";
import { Content } from "./Content";
import { Title } from "./Title";

export default function App() {
  return (
    <InFocusProvider>
        <Title />
        <div className="TopLevel">
          <Content />
          <FooterItem />
        </div>
    </InFocusProvider>
  );
}
