import React, { createContext, FC, useCallback, useState } from 'react';
import { useDebounce } from '../debounce/debounce';

export interface InFocusContextType {
  focusedElementId: number | null;
  updateInFocus: (obj: Partial<InFocusState>) => void;
  visibleEventCounter: number;
  updateVisibleEventCounter: (delta: number) => void;
}

interface InFocusState {
  focusedElementId: number | null;
  visibleEventCounter: number;
}

export const InFocusContext = createContext<InFocusContextType | null>(null);

interface InFocusProviderProps {
  children: React.ReactNode;
}

export const InFocusProvider: FC<InFocusProviderProps> = ({ children }) => {
  const [focusedElementId, setFocusedElementId] = useState<number | null>(null);
  const [visibleEventCounter, setVisibleEventCounter] = useState(0);

  const updateInFocus = (obj: Partial<InFocusState>) => {
    if (obj.focusedElementId !== undefined) {
      setFocusedElementId(obj.focusedElementId);
    }
    if (obj.visibleEventCounter !== undefined) {
      setVisibleEventCounter(obj.visibleEventCounter);
    }
  };

  const debouncedFunction = useDebounce((delta: number) => {
    setVisibleEventCounter(prevState => prevState + delta);
  }, 100);

  const updateVisibleEventCounter = useCallback(debouncedFunction, [debouncedFunction]);


  return (
    <InFocusContext.Provider value={{ focusedElementId, visibleEventCounter, updateInFocus, updateVisibleEventCounter }}>
      {children}
    </InFocusContext.Provider>
  );
};
