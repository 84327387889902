import "./index.css";

export interface CircleProps {
  num: number;
  opacity: number;
}

const circle = window.innerWidth >= 600 ? {
  Radius: 120,
}: {
  Radius: 40,
};

export const getCircleId = (index: number) =>{
  return "circle-" + index.toString();
}

export const Circle = ({ num, opacity }: CircleProps) => {
  return (
    <div className="Circle">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width={(circle.Radius * 2).toString() + "px"}
        height={(circle.Radius * 2).toString()}
        opacity={opacity}
        role='circle-svg'
      >
        <circle
          id={getCircleId(num)}
          cx={circle.Radius}
          cy={circle.Radius}
          r={circle.Radius * 0.75}
          role='circle'
        />
      </svg>
      {/* DEBUG <p>entry={entry?.intersectionRatio}</p>
          <p>opacity={opacity}</p> */}
    </div>
  );
};
