import { useContext } from 'react';
import { InView } from 'react-intersection-observer';
import { InFocusContext } from './InFocusContext/InFocusContext';
import { EventItem } from './EventItem';
import { HistoricalItem, data } from './data/history';

export const Content = () => {
  const inFocusContext = useContext(InFocusContext);

  if (!inFocusContext) {
    // Handle the case where InFocusContext is null (perhaps by returning a fallback component or null)
    return null;
  }

  const { updateInFocus } = inFocusContext;

  return (
    <InView 
      as="div" 
      onChange={(inView, entry) => {
        updateInFocus({ visibleEventCounter: inView ? 1 : 0 });
      }} 
    threshold={0.5}
    className="ContentContainers">
      <div className="App content">
        <div className="StartLower"></div>
        <>
          {data.events.map((item: HistoricalItem, index: number) => {
            return (<EventItem index={index} item={item} />);
          })}
        </>
        <div className="StartLower"></div>
        {/* Add an extra space at the end of the list */}
        <div style={{ height: '200px' }} />
      </div>
    </InView>
  );
};

