import React, { useContext } from "react";
import { InView } from "react-intersection-observer";
import { HistoricalItem } from "./data/history";
import { Circle } from "./display/circle";
import { InFocusContext } from "./InFocusContext/InFocusContext";
import { useDebounce } from "./debounce/debounce";

export interface EventProps {
  index: number;
  item: HistoricalItem;
}

const buildThresholdArray = () => Array.from(Array(100).keys(), (i) => i / 100);
//const buildThresholdArray = () => Array.from(Array(50).keys(), (i) => ((i*2)%100) / 100).concat(Array<number>(50).fill(0));

export const EventItem = ({ index, item }: EventProps) => {
  const [opacity, setOpacity] = React.useState(0);
  const inFocusContext = useContext(InFocusContext);

  const debouncedUpdateInFocus = useDebounce((newFocusedElementId: number | null, intersectionRatio: number) => {
    if (focusedElementId === null || intersectionRatio > 0.90) {
      updateInFocus({ focusedElementId: newFocusedElementId });
    }
  }, 200);
  if (!inFocusContext) {
    // Handle the case where InFocusContext is null (perhaps by returning a fallback component or null)
    return null;
  }

  const { updateInFocus, focusedElementId } = inFocusContext;

  const onIntersect = (entry: IntersectionObserverEntry) => {
    const newOpacity = Math.pow(entry.intersectionRatio, 2);
    setOpacity(newOpacity);

    if(newOpacity > 0.90) {
      debouncedUpdateInFocus(index, entry.intersectionRatio);
    } else if (newOpacity < 0.10 && focusedElementId === index) {
      updateInFocus({ focusedElementId: null });
  }
  };

  return (
    <InView
      onChange={(inView, entry) => {
        onIntersect(entry);
      }}
      threshold={buildThresholdArray()}
      rootMargin="-200px 0px -200px 0px"
    >
      {({ inView, ref, entry }) => (
        <div className="EventBlock" ref={ref}>
          {Array.from({ length: item.level }).map((_, index) => (
            <div key={index} className="Space" />
          ))}
          <Circle num={index} opacity={opacity} />
          <h2 className="Text" style={{ opacity: opacity }}>
            {item.text}
          </h2>
        </div>
      )}
    </InView>
  );
};
