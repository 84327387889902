import React from "react";
import { InView } from "react-intersection-observer";

const buildThresholdArray = () => Array.from(Array(100).keys(), (i) => i / 100);

export const Title = () => {
  const [opacity, setOpacity] = React.useState(0);

  const onIntersect = (entry: any) => {
    setOpacity(entry.intersectionRatio);
    //setOpacity(Math.pow(entry.intersectionRatio, 2) / 1);
  };

  return (
    <div className="TopLevel">
      <p className="Title">Anthony Ashton</p>
      <p className="Subtitle">My career as an event sourced system :-)</p>
      <InView
        onChange={(inView, entry) => {
          onIntersect(entry);
        }}
        threshold={buildThresholdArray()}
        rootMargin="-40% 0px 0px 0px"
      ></InView>
      <div style={{opacity: opacity}}>
        <p className="CallToActionSymbol">⌄</p>
      </div>
    </div>
  )
}