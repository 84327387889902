import { useContext, useEffect, useMemo } from "react"
import { InFocusContext, InFocusContextType } from "./InFocusContext/InFocusContext"
import { data } from "./data/history";

export const FooterItem = () => {
  const inFocus = useContext(InFocusContext) as InFocusContextType;

  const item = useMemo(() => {
    return inFocus.focusedElementId !== null ? data.events[inFocus.focusedElementId] : null;
  }, [inFocus.focusedElementId]);
  // console.log(item)

  useEffect(() => {
    console.log('visibleEventCounter changed:', inFocus.visibleEventCounter);
  }, [inFocus.visibleEventCounter]);

  if (inFocus.visibleEventCounter === 0) {
    return null;  // Don't render the footer if no events are visible
  }

  return (
    <div className="FootView">
      <p className="FootText"> 
        {item?.title && item.title + ' ·'} 
        {item?.year}
      </p>
    </div>
  );
};

