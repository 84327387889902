import React from "react";

export function useDebounce(func: Function, wait: number) {
  const timeoutRef = React.useRef<number | null>(null);

  return React.useCallback(
    (...args: any[]) => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = window.setTimeout(() => {
        func(...args);
      }, wait);
    },
    [func, wait],
  );
}